import React from "react";
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import '../../assets/css/cgfns.css';

const ConfirmPaymentDetails = ({ selectedPayments, onClose, onPayClick, isPayAPICalled }) => {
  return (
    <div>
      {/* Overlay */}
      <div className="sp-overlay"></div>
      {/* Popup */}
      <div className="sp-popup">
        <h3>Confirm Payment Details</h3>
        <div style={{ overflowY: "auto", maxHeight: "1000px" }}>
          <table className="sp-table">
            <thead>
              <tr>
                {isPayAPICalled && <th className="sp-table-header">Status</th>}
                <th className="sp-table-header">Applicant</th>
                <th className="sp-table-header">Program</th>
                <th className="sp-table-header sp-table-cell-right">Amount</th>
                {isPayAPICalled && <th className="sp-table-header">Response</th>}
              </tr>
            </thead>
            <tbody>
              {selectedPayments.map((payment, index) => (
                <tr key={index}>
                  {isPayAPICalled && (
                    <td className="sp-table-cell sp-table-cell-center">
                      {payment.status === 'Success' ? (
                        <FaCheckCircle style={{ color: 'green' }} size={20} />
                      ) : (
                        <FaTimesCircle style={{ color: 'red' }} size={20} />
                      )}
                    </td>
                  )}
                  <td className="sp-table-cell">{`${payment.firstName} ${payment.lastName}`}</td>
                  <td className="sp-table-cell">{payment.program}</td>
                  <td className="sp-table-cell sp-table-cell-right">{payment.lineBalance}</td>
                  {isPayAPICalled && (<td className="sp-table-cell">{payment.message}</td>)}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="sp-button-container">
          <button
            onClick={onClose}
            className="sp-button sp-cancel-button"
          >
            {!isPayAPICalled ? 'Cancel' : 'Close'}
          </button>
          {!isPayAPICalled && <button
            onClick={onPayClick}
            className="sp-button sp-pay-button"
          >
            Pay
          </button>}
        </div>
      </div>
    </div>
  );
};

export default ConfirmPaymentDetails;

import React, { useState, useEffect } from "react";
import useAuth from "../../azureLogin/useAuth";
import { protectedResources } from "../../azureLogin/msalPayerConfig";
import PPHeader from "./PPHeader/PPHeader";
import { PayerForm, updatedOrders } from "./PayerForm.js";
import "../Payments/paymentForm.module.css";
import ConfirmPaymentDetails from "./ConfirmPaymentDetails.js";

function formatUSD(val) {
  val ||= 0;
  const s = Math.round(val * 100).toString();
  if (s.length === 1) return `$0.0${s}`;
  else if (s.length === 2) return `$0.${s}`;
  const e = s.length - 3;
  let n = "." + s.slice(-2);
  for (let i = 0; i <= e; i++) {
    if (i && i % 3 === 0) n = "," + n;
    n = s[e - i] + n;
  }
  return "$" + n;
}

function PaymentTable() {
  const { fetchData, postData } = useAuth();
  const [tableData, setTableData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPayments, setSelectedPayments] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [rowToRemove, setRowToRemove] = useState(null);
  const [showPayerForm, setPayerForm] = useState(false);
  const [showPaymentConfirm, setShowPaymentConfirm] = useState(false);
  const [thirdPartyPayerEmail, setThirdPartyPayerEmail] = useState("");
  const [isPayAPICalled, setIsPayAPICalled] = useState(false);
  const [isPaymentHeaderHidden, setPaymentHeaderHidden] = React.useState(true);

  useEffect(() => {
    fetchData(protectedResources.apiPayer.queries.orders)
      .then((response) => {
        const ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(ninetyDaysAgo.getDate() - 90);

        const filteredData = response.data.filter(
          (item) => item.lineBalance > 0 && new Date(item.createTime) > ninetyDaysAgo
        );
        
        const fetchedData = filteredData.map((item, index) => ({
          id: index + 1,
          name: `${item.Contact.FirstName || ""} ${item.Contact.LastName || ""}`,
          cgfsnId: item.Contact.Applicant_ID__c || "N/A",
          order: item.orderNumber,
          orderName: item.orderType,
          price: formatUSD(item.lineBalance),
          orderHeadersId : item.orderHeadersId
        }));
        setTableData(fetchedData);

        if (response.data[0]?.ThirdPartyPayerEmail) {
          setThirdPartyPayerEmail(response.data[0].ThirdPartyPayerEmail);
        }
      })
      .catch((err) => {
        console.error("Error fetching orders:", err);
        setError("Failed to fetch orders. Please try again later.");
      });
  }, []);

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(tableData.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  const handleSelectRow = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleRemoveClick = (id) => {
    setRowToRemove(id);
    setShowPopup(true);
  };

  const handleConfirmRemove = () => {
    // Find the order number of the row to remove
    const rowToRemoveData = tableData.find((row) => row.id === rowToRemove);

    if (rowToRemoveData) {
      const orderId = rowToRemoveData.order;
  
      const response =  postData(`${protectedResources.apiPayer.queries.declinepayment}${orderId}`, 
        {
          headers: {
            "Content-Type": "application/json",
          },
    
        })



        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to decline payment request for order: ${orderId}`);
          }
          return response.json();
        })
        .then((data) => {
          console.log("Payment request declined successfully:", data);
  
          // Update the table data after successful API call
         setTableData(tableData.filter((row) => row.id !== rowToRemove));
          setSelectedRows(selectedRows.filter((rowId) => rowId !== rowToRemove));
          setShowPopup(false);
          setRowToRemove(null);
        })
        .catch((error) => {
          console.error("Error declining payment request:", error);
        });
    } else {
      console.error("Row to remove not found.");
    }
  };

  const handleCancelRemove = () => {
    setShowPopup(false);
    setRowToRemove(null);
  };

  const handleProceedToPayment = () => {
    const selectedData = tableData
      .filter((row) => selectedRows.includes(row.id))
      .map((row) => ({
        orderNumber: row.order,
        firstName: row.name.split(" ")[0] || "",
        lastName: row.name.split(" ")[1] || "",
        program: row.orderName,
        lineBalance: row.price,
        orderHeadersId : row.orderHeadersId
      }));
    setSelectedPayments(selectedData);
    setPayerForm(false);
    setShowPaymentConfirm(true);
    setIsPayAPICalled(false);
    setPaymentHeaderHidden(false);
  };

  const calculateTotalAmount = () => {
    return selectedRows
      .map((id) => {
        const row = tableData.find((row) => row.id === id);
        return row ? parseFloat(row.price.replace(/[$,]/g, "")) : 0;
      })
      .reduce((sum, value) => sum + value, 0);
  };

  const handleClosePopup = () => setShowPaymentConfirm(false);

  const handlePayClick = () => {
    setShowPaymentConfirm(false);
    setPayerForm(true);
  };

  const handlePaySubmitClick = () => {
    setShowPaymentConfirm(true);
    setPayerForm(false);
    setIsPayAPICalled(true);
  };

  return (
    <>
      <PPHeader />
      <div className="payment-container">
        {error && <div className="error-message">{error}</div>}

        {isPaymentHeaderHidden &&<div className="payment-header">
          <h5>Payment Requests for {thirdPartyPayerEmail && `${thirdPartyPayerEmail}`}</h5>
          <p>
            Please select the record(s) you would like to submit payment for and select pay.
            If you select more than one record you can pay multiple accounts at one time (up to ten records).
          </p>
          <p>
            If you feel that you have been requested to make this payment in error you can select
            <strong> Remove Payment Request</strong> and the applicant will be notified to submit payment themselves.
          </p>
        </div>}
        {!showPayerForm && (
          <>
            <table className="payment-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAll}
                      className="small-checkbox"
                    />
                  </th>
                  <th>Applicant Name</th>
                  <th>CGFNS ID</th>
                  <th>Order</th>
                  <th>Order Name</th>
                  <th className="price-column">Price</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row) => (
                  <tr key={row.id}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(row.id)}
                        onChange={() => handleSelectRow(row.id)}
                        className="small-checkbox"
                      />
                    </td>
                    <td>{row.name}</td>
                    <td>{row.cgfsnId}</td>
                    <td>{row.order}</td>
                    <td>{row.orderName}</td>
                    <td className="price-column">{row.price}</td>
                    <td>
                      <button
                        onClick={() => handleRemoveClick(row.id)}
                        className="remove-button"
                      >
                        Remove Payment Request
                      </button>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td colSpan="5" className="total-label payment-table-noborder">
                    Total Amount:
                  </td>
                  <td className="total-value payment-table-noborder">{formatUSD(calculateTotalAmount())}</td>
                  <td className=" payment-table-noborder">
                    <button
                      className={`payment-button ${selectedRows.length < 1 ? "disabled" : ""}`}
                      onClick={handleProceedToPayment}
                      disabled={selectedRows.length < 1}
                    >
                      Proceed to Payment
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        {showPayerForm && selectedPayments.length > 0 && (
          <PayerForm
            handlePaySubmitClick={handlePaySubmitClick}
            orders={selectedPayments} email={thirdPartyPayerEmail}
          />
        )}
        {showPopup && (
          <>
            <div className="popup-overlay" onClick={handleCancelRemove}></div>
            <div className="popup">
              <p>Are you sure you would like to remove this request for payment?</p>
              <p>If you select confirm, the applicant will be removed.</p>
              <div className="popup-actions">
                <button onClick={handleCancelRemove} className="cancel-button">
                  Cancel
                </button>
                <button onClick={handleConfirmRemove} className="confirm-button">
                  Confirm
                </button>
              </div>
            </div>
          </>
        )}

        {showPaymentConfirm && (
          <ConfirmPaymentDetails
            selectedPayments={selectedPayments}
            isPayAPICalled={isPayAPICalled}
            onClose={handleClosePopup}
            onPayClick={handlePayClick}
          />
        )}
      </div>
    </>
  );
}

export default PaymentTable;
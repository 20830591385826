import { useMsal} from '@azure/msal-react';
import { InteractionRequiredAuthError , BrowserAuthError,  InteractionType} from '@azure/msal-browser';
import { loginRequest as trackerLoginRequest} from './msalConfig';
import { loginRequest as payerLoginRequest } from './msalPayerConfig';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useMsalAuthentication } from "@azure/msal-react";



const useAuth = () => {

  const [token, setToken] = useState(null);
  const [ tokenEmail, setTokenEmail ] = useState();
  const { instance,accounts, inProgress } = useMsal();


  const [interactionInProgress, setInteractionInProgress] = useState(false);
  const loginAttempted = useRef(false);
  const popupClosed = useRef(false);
  const loginRequest = window.location.pathname.startsWith("/pay") ? payerLoginRequest : trackerLoginRequest;



  const {login, result, error} = useMsalAuthentication(InteractionType.Silent, loginRequest);

    useEffect( () => {
      if ((error instanceof InteractionRequiredAuthError) || (error instanceof BrowserAuthError)) {
          login(InteractionType.Redirect, request);
      }
      }, [error]);





async function fetchData(url,cnfg) {

   cnfg ||= {};

  try {
      setInteractionInProgress(true);

      if (!interactionInProgress) {

        if (accounts?.length)
          instance.setActiveAccount(accounts[0]);

       let response;
       try  {
          response = await instance.acquireTokenSilent({ ...loginRequest });
        }
        catch(error)  {
          if ((error instanceof InteractionRequiredAuthError) || (error instanceof BrowserAuthError)) {
              // fallback to interaction when silent call fails
              response = await instance.acquireTokenRedirect({ ...loginRequest });
          }
          else
            throw error;
        }
      
        //assume GET method if not specified
        cnfg.method ||= "GET";
        cnfg.headers ||= {};
        
        //if this request is to the Integration Server, add our Access Token as the Bearer Token
       cnfg.headers.Authorization = `Bearer ${response.accessToken}`;
       cnfg.url = url.startsWith("http") ? url : `${process.env.REACT_APP_WEBSITE_NAMES_AZURE}${url}`;
       return axios.request(cnfg);
     }
  } 
  catch (error) {
    console.log("Failed to fetch data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
    finally {
      setInteractionInProgress(false);
    }
  }
  


  async function postData(url,formData,cnfg) {
    try {
      const account = accounts[0];
      if (!account) {
        console.log("No account found");
        return;
      }

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });

      cnfg ||= {};
      cnfg.method ||= "GET";
      cnfg.headers ||= {}; 
      
      //if this request is to the Integration Server, add our Access Token as the Bearer Token
       cnfg.headers.Authorization = `Bearer ${response.accessToken}`; 
       return axios.post( url.startsWith("http") ? url : `${process.env.REACT_APP_WEBSITE_NAMES_AZURE}${url}`,formData,cnfg);

    } catch (error) {
      console.log("Failed to post data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };

  async function request(url,cnfg) {
    try {
      const account = accounts[0];
      if (!account) {
        console.log("No account found");
        return;
      }

      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account,
      });
      
       
      //if this request is to the Integration Server, add our Access Token as the Bearer Token
       cnfg.headers.Authorization = `Bearer ${response.accessToken}`; 
       cnfg.url =`${process.env.REACT_APP_WEBSITE_NAMES_AZURE}${url}`;
       return axios.request(cnfg);

    } catch (error) {
      console.log("Failed to post data:", error);
      throw error; // Rethrow the error for the caller to handle
    }
  };


  const logout = async () => {
    if (inProgress == "none") {
    try {
      await instance.logoutRedirect({
        postLogoutRedirectUri: window.location.href,
      });
      setToken(null);
      console.log("User logged out and session cleared");
    } catch (error) {
      console.log("Error during logout: ", error);
      //setError(error.message || "Logout failed");
    }
  }
  };


  return { token, fetchData, postData, request, error, logout };

};

export default useAuth;

import React from "react";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import Switch from "react-router-dom"
import Header from "../components/cgfns/cgfnsHeader/cgfnsHeader";
import Footer from "../components/cgfns/cgfnsFooter/cgfnsFooter";
import CgfnsStatusTracker from "../Pages/cgfns/cgfnsStatusTracker/cgfnsStatusTracker";
import IcdHomePage from "../Pages/icd/icdHomePage/icdHomePage";
import IcdOrderHistory from "../Pages/icd/icdOrderHistory/icdOrderHistory";
import IcdStatusTracker from "../Pages/icd/icdStatusTracker/icdStatusTracker";
import CgfnsOrderHistory from "../Pages/cgfns/cgfnsOrderHistory/cgfnsOrderHistory";
import CgfnsHomePage from "../Pages/cgfns/cgfnsHomePage/cgfnsHomePage";
import PaymentsSite from "../Pages/payments/paymentsSite.js";
import PayerOrdersView from "../components/Payments/PayerOrdersView";
import PaidOrders  from "../components/Payments/PaidOrders.js";
import PPHeader from "../components/Payments/PPHeader/PPHeader.js";
export default function RoutingConfig() {

    const location = useLocation();
    const isIcdPortal = location.pathname.startsWith('/icd')

    return (
        <Routes>
        {/* CGFNS Portal Routes */}
        <Route path="/cgfns/home" element={<CgfnsHomePage />} />
        <Route path="/cgfns/orderhistory" element={<CgfnsOrderHistory />} />
        <Route path="/cgfns/statustracker" element={<CgfnsStatusTracker />} />
        <Route path="/cgfns/header" element={<Header />} />
        <Route path="/cgfns/footer" element={<Footer />} />

        {/* ICD Portal Routes */}
        <Route path="/icd/home" element={<IcdHomePage />} />
        <Route path="/icd/orderhistory" element={<IcdOrderHistory />} />
        <Route path="/icd/statustracker" element={<IcdStatusTracker />} />

         {/* Common Portal Routes */}
         <Route path="/payments" element={<PaymentsSite />} />
         <Route path="/Payments/PPHeader" element={<PPHeader />} />
         <Route path="/paymentportal/payerorders" element={<PayerOrdersView />} /> 
         <Route path="/paymentportal/" element={<Navigate to="/paymentportal/payerorders" />} />
         <Route path="/paymentportal/paidorders" element={<PaidOrders />} />
        
         
        {/* Redirect to appropriate default route */}
        <Route path="/" element={<Navigate to={isIcdPortal ? "/icd/home" : "/cgfns/home"} />} />
      </Routes>

        
  /*           <Routes>
                 <Switch> 
                    <Route path="/Header" element={<Header />} />
                    <Route path="/Footer" element={<Footer />} />
                    <Route path="/Homepage" element={<HomePage />} />
                    <Route path="/OrderHistory" element={<OrderHistory />} /> 
                    <Route path="/CgfnsStatusTracker" element={<CgfnsStatusTracker />} />
                    <Route path="/IcdHomePage" element={<IcdHomePage />} />
                    <Route path="/IcdOrderHistry" element={<IcdOrderHistory />} />
                    <Route path="/IcdStatusTracker" element={<IcdStatusTracker />} />

                    Redirect to a default route if none match 
                    <Route path="/" component={isIcdPortal ? IcdHomePage : HomePage} />


                 </Switch> 

            </Routes> */
        
    );
}

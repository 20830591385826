import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/css/cgfns.css" 
import logo from '../../../assets/image/logo.png';
import { Button } from 'react-bootstrap';
import useAuth from '../../../azureLogin/useAuth';

const PPHeader = () => {
    const { logout } = useAuth();
    const [hover, setHover] = useState(false);

    return (
        <nav className="navbar navbar-expand-lg navbar-custom-cgfns">
            <div className="container-fluid">
                <a className="navbar-brand" href="/">
                    <img src={logo} alt="CGFNS Logo" width="180" />
                </a>
                <div className="d-flex">
                    <Button
                        className={`logout-button ${hover ? 'hover' : ''}`}
                        variant="outline-primary"
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}
                        onClick={logout}
                    >
                        Logout
                    </Button>
                </div>
            </div>
        </nav>
    );
};

export default PPHeader;
